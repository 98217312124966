// Clean!
import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import { Routes, Route, BrowserRouter } from 'react-router-dom';

const Table = lazy(()=> import("./Table"))
const PhoneTable = lazy(()=> import("./PhoneTable"))
const Welcome = lazy(()=> import("./Welcome"))
const Page404 = lazy(()=> import("./404"))

const App = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={ null }>
                <Routes>
                    <Route exact path='/' element={< Index />}></Route>
                    <Route exact path='/fa' element={< Index lang="fa" />}></Route>
                    <Route exact path='/en' element={< Index lang="en" />}></Route>
                    <Route exact path='/tr' element={< Index lang="tr" />}></Route>

                    <Route path='*' element={ <Page404 /> } />
                    <Route path='*/' element={ <Page404 /> } />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

class Index extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            content: null
        }
    }
    componentDidMount() {
        // this.setState({
        //     content: (
        //         <div className="page_loading">
        //             <div/>
        //             <div/>
        //             <div/>
        //             <div/>
        //             <div/>
        //             <div>- : Loading : -</div>
        //         </div>
        //     )
        // })
        let light = false, lang = this.props.lang ? this.props.lang : "en", title;
        if (window.innerWidth < 940 || localStorage.getItem('light'))
        {
            light = 'true';
        }
        axios.get('https://api.ipregistry.co/?key=9m6fzjcopb9itwws')
            .then((response)=>{
                if(this.props.lang) {
                    switch (this.props.lang) {
                        case "fa":
                            title = "رزومه تعاملی یاور جلالی";
                            break;
                        case "tr":
                            title = "Etkileşimli özgeçmiş | Yaver Celali";
                            break;
                        case "en":
                            title = "Interactive resume of Yavar Jalali";
                            break;
                        default:
                            break;
                    }
                }
                else {
                    switch (response.data.location.country.code) {
                        case "IR":
                            lang = "fa";
                            title = "رزومه تعاملی یاور جلالی";
                            break;
                        case "AF":
                            lang = "fa";
                            title = "رزومه تعاملی یاور جلالی";
                            break;
                        case "TR":
                            lang = "tr";
                            title = "Etkileşimli özgeçmiş | Yaver Celali";
                            break;
                        case "AZ":
                            lang = "tr";
                            title = "Etkileşimli özgeçmiş | Yaver Celali";
                            break;
                        default:
                            lang = "en";
                            title = "Interactive resume of Yavar Jalali";
                            break;
                    }
                }
                
                axios.put('https://jalali.dev/api/visit.php', {
                    country: response.data.location.country.name
                })
                document.getElementById('title').innerText = title;

                // setTimeout(()=>{
                    if (window.innerWidth < 769){
                        this.setState({
                            content: (
                                <React.Fragment>
                                    <Suspense fallback={ null }>
                                        <PhoneTable lang={lang} />
                                        <Welcome phone lang={lang} country={response.data.location.country.name} code={response.data.location.country.code} />
                                    </Suspense>
                                </React.Fragment>
                            )
                        })
                    }
                    else
                        this.setState({
                            content:(
                                <React.Fragment>
                                    <Suspense fallback={ null }>
                                        <Table light={light} lang={lang} />
                                        <Welcome lang={lang} country={response.data.location.country.name} code={response.data.location.country.code} />
                                    </Suspense>
                                </React.Fragment>
                            )
                        });
                // },7600)

                light = lang = title = null;
            })
            .catch(()=>{
                axios.put('https://jalali.dev/api/visit.php', {
                    country: 'Unknown!'
                })
                if (window.innerWidth < 769){
                    this.setState({
                        content: (
                            <React.Fragment>
                                <Suspense fallback={ null }>
                                    <PhoneTable lang={lang} />
                                    <Welcome phone lang={lang} code={'0'} />
                                </Suspense>
                            </React.Fragment>
                        )
                    })
                }
                else
                    this.setState({
                        content:(
                            <React.Fragment>
                                <Suspense fallback={ null }>
                                    <Table light={light} lang={lang} />
                                    <Welcome lang={lang} code={'0'} />
                                </Suspense>
                            </React.Fragment>
                        )
                    });
                light = lang = title = null;
            })
    }
    render(){
        return(
            this.state.content
        )
    }
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
